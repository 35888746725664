<template>
  <b-container>
    <div v-if="chartStat" id="chart">
      <apexchart type="donut" :options="chartOptions" :series="chartData"></apexchart>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["data","height","customColors","currency"],
  computed: {
    isCurrency(){
      return this.$props.currency
    },
    colors(){
      if (this.$props.customColors){
        return this.$props.customColors
      } else {
        return ["#0275d8", "#0a2287"]
      }
    },
    chartStat() {
      return this.$props.data;
    },
    chartLabels() {
      return Object.keys(this.chartStat).map((item) => {
        return this.chartStat[item].label;
      });
    },
    chartData() {
      return Object.keys(this.chartStat).map((item) => {
        return this.chartStat[item].count;
      });
    },
    chartOptions() {
      return {
        chart: {
          type: "donut",
        },
        colors: this.colors,

        tooltip: {
          y: {
            formatter(val,isCurrency) {
              if (isCurrency){
                return "$" + val + " B";
              } else {
                return val
              }
            },
          },
        },

        labels: this.chartLabels,

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: { height: this.$props.height, width: 300 },
            },
          },
        ],
        legend: {
          position: "bottom",
          style: {
            fontSize: "0.7rem",
          },
        },
      };
    },
  },
};
</script>
