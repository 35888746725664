<template>
    <div :id="title" class="item d-flex  align-items-center">
      <div class="container">
        <div class="row align-items-center">
          <b-col md="12" lg="12">
            <h6 class="figure-number-small">{{number}}</h6>
            <h4 class="figure-title-small mb-5">{{title.toUpperCase()}}</h4>
            <b-img :width="$props.width?$props.width :'600vw'" :src="image"  />
          </b-col>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    props:["figure", "width"],
    data(){
        return{

        }
    },
    computed:{
        title(){
            return this.$props.figure.title
        },
        image(){
            return this.$props.figure.src
        },
        number(){
            return this.$props.figure.figure
        }
    }
}
</script>

<style scoped>
.item {
  position: relative;
}

.figure-title {
  color: #0a2287;
  padding: 3rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.figure-title-small {
  color: #0a2287;
  padding-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
}

.figure-number-small {
  color: #8296b0;
  /* padding: 1rem; */
  font-size: 1rem;
  font-weight: 400;
}
</style>