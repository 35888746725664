<template>
    <b-container>

        <!-- LOADER -->
        <div class="text-center my-5">
        <MTBLoader v-if="items.length === 0" />
        </div>

        <!-- TABLE -->
        <b-row v-if="isLoaded">
            <b-col md="12">
                <b-table small stacked="md" :items="paginatedItems" :fields="fields" :sort-by="sortBy" :sort-desc="sortDesc">
                    <template v-slot:cell(Company)="data">
                       <!-- <a :href="data.item['Website']"> -->
                           <b-card-img class="mb-4 logo" :src="data.item['Logo URL']?data.item['Logo URL'][0] : null" top @error="noImage"></b-card-img>
                        <!-- </a>  -->
                    </template>
                    <template v-slot:cell(YoE)="data">
                        <p>{{data.item['Year of Establishment']?data.item['Year of Establishment'][0] : ""}}</p>
                    </template>
                    <template v-slot:cell(IPOFunding)="data">
                        <p>{{data.item['IPO Funding']|formatCurrency}}</p>
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <!-- CARDS GRID -->
        <!-- <b-row>
            <b-col class="mt-2" md="12">
                <div class="my-grid">
                <b-card id="grid-card" v-for="(item, index) in paginatedItems" :item="item" :key="item['TXN-ID']" v-bind:data-index="index" style="max-width: 20rem" border-variant="light" class="mb-2 rounded shadow cards-grid">
                    <b-card-img class="mb-4" :src="item['Logo URL']" top @error="noImage"></b-card-img>
                    <h5 class="mb-3"> <a :href="item['Website']" target="__blank">{{ item["Company Name"][0] }}</a></h5>
                    <b-card-text>
                    <p><v-icon name="cog" class="text-primary" scale="1" />  {{ item["Year of Establishment"][0]}}</p>
                    <p><v-icon name="map-marker-alt" class="text-primary" scale="1" /> {{ item["IPO/ICO Year"]}}</p>
                    <p><v-icon name="dollar-sign" class="text-primary" scale="1" /> {{ item["IPO Funding"] | formatCurrency }}</p>
                    <p><v-icon name="map-marker-alt" class="text-primary" scale="1" /> {{ item["IPO/ICO Market Name"]}}</p>
                    </b-card-text>
                </b-card>
                </div>
            </b-col>
        </b-row> -->

        <!-- TABLE PAGINATION -->
        <b-row v-if="items.length !== 0">
            <b-col md="12">
                <b-pagination @page-click="scrollTop" pills class="mt-3" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>
import MTBLoader from "../../components/MTBLoader";
import util from "@/support/util"
export default {
    components:{MTBLoader},
    props:["payload"],
    data(){
        return{
            isLoaded:false,
            currentPage:1,
            perPage:8,
            sortBy:"Capital Raised",
            sortDesc:true,
            fields:[
                { key: "Company", label:"Company", sortable: true },
                { key: "HQ", label:"HQ", sortable: true },
                { key: "YoE", label:"Year of Establishment", sortable: true },
                { key: "IPO/ICO Year", label:"Year of IPO", sortable: true },
                { key: "IPO/ICO Market Name", label:"IPO Market", sortable: true },
                { key: "IPOFunding", label:"Capital Raised", sortable: true },
            ],
        }
    },
    computed:{
        items() {
            return this.$store.state.items
        },
        paginatedItems() {
            const { currentPage, perPage } = this;
            const start = (currentPage - 1) * perPage;
            const end = currentPage * perPage;
            return this.items.slice(start, end);
        },
        totalRows() {
            return this.items.length;
        }
    },
    created(){
        this.noImage = util.noImage;
        this.scrollTop = util.scrollTop;
        this.$store.dispatch("loadIPO", this.$props.payload).then(()=>{this.isLoaded=true})
    },
}
</script>

<style scoped>
.search-bar {
  border-radius: 10px;
  border: none;
  justify-items: center;
}




.my-grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5rem;
}
.my-grid > * {
  width: 100%;
  max-width: 20rem;
}
.search-bar {
  border-radius: 10px;
  border: none;
}
.card:hover {
  -webkit-transform: scale(1.02, 1.02);
  transform: scale(1.02, 1.02);
}
#grid-card .card:hover::after {
  opacity: 1;
}
.card {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
/* .card-img-top {
  margin-top: 0vw;
  width: 100%;
  height: 1.5vw;
  object-fit: scale-down;
  max-width: 6rem;
  min-height: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
} */
.card-img-top {
  width: 100%;
  height: 1vw;
  object-fit: scale-down;
  max-width: 4rem;
    max-height: 2rem;

  /* min-height: 2.2rem; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

</style>