<template>
    <b-container>
        <div class="mr-4" style="text-align:right!important;">
                <b-row class="justify-content-end">
                 <b-avatar  class="mb-2 shadow" variant="info" :src="picture" size="calc(15px + 4vw)"></b-avatar>
                </b-row>
                <b-row class="justify-content-end">
                  <span style="font-size:calc(10px + 0.5vw);">{{author}}</span>
                  <span style="font-size:calc(8px + 0.25vw);">{{title}}</span>
                </b-row>
              </div>
    </b-container>
</template>

<script>
export default {
    name:"Author",
    props:["picture","author","title"],
    data(){
        return{

        }
    }
}
</script>