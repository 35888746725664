<template>
 <div :id="title? title :''" :class="deepdive?'dark-bg item d-flex align-items-center'  :'item d-flex align-items-center'" :style="!$props.font? 'font-size:16px;' :`font-size:${$props.font}px;`">
      <div class="title">{{title}}</div>
      <div class="container mt-5">
        <b-row v-if="$props.fullwidth==true">
          <b-col offset-md="2" md="8">
            <p v-for="(item,index) in $props.text" :key="index" v-html="item"/>
          </b-col>
        </b-row>
        <b-row v-if="!$props.singleColumn">
          <b-col md="6">
            <p v-for="(item,index) in $props.left" :key="index" v-html="item"/>
          </b-col>
          <b-col md="6">
            <p v-for="(item,index) in $props.right" :key="index" v-html="item"/>
          </b-col>
        </b-row>
        <b-row v-if="$props.footnotes.length!==0" class="mt-5" style="text-align:left!important;">
          <b-col>
            <p class="small" v-for="(item,index) in $props.footnotes" :key="index" v-html="item"/>
          </b-col>
        </b-row>
      </div>
    </div>
</template>

<script>
export default {
    props:["titleSection","left","right","footnotes","fullwidth","deepdive","font","text"],
    data(){
        return{

        }
    },
    computed:{
        title(){
            return this.$props.titleSection
        },
        image(){
            return this.$props.figure.src
        },
        number(){
            return this.$props.figure.figure
        }
    }
}
</script>

<style scoped>
.item {
  position: relative;
}
white-bg {
  background: rgb(255, 255, 255);
}

.grey-bg {
  background: rgb(247, 247, 247);
}

.dark-bg {
  background: #051930;
  /* background:#061655; */
  /* background:#0B2187; */
  color: white !important;
}

p, li {
  text-align: justify !important;
}

.subtitles {
  color: #8296b0;
  font-size: 1.1rem;
  font-style: italic;
  font-weight: normal;
}

.dark-bg .subtitles {
  color: lightgray;
  font-size: 1.1rem;
  font-style: italic;
  font-weight: 9 00;
}

.dark-bg .title {
  color: white;
  font-size: calc(20px + .5vw);
  margin-top: 1rem;
  position: absolute;
  top: 2%;
  left: 8%;
}

.dark-bg .title::after {
  /* this is the border */
  display: block;
  position: absolute;
  width: 4em;
  max-width: 100%;
  border-bottom: 3px solid white;
  margin: 0.25em auto 0;
}


.title {
  color: #0a2287;
  font-size: calc(20px + .5vw);
  margin-top: 1rem;
  position: absolute;
  top: 2%;
  left: 8%;
}

.title::after {
  /* this is the border */
  content: "";
  display: block;
  position: absolute;
  width: 4em;
  max-width: 70%;
  border-bottom: 3px solid #0a2287;
  margin: 0.25em auto 0;
}


</style>