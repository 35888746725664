<template>
  <b-container>
    <div id="chart" class="my-3">
      <apexchart v-if="chartStat" type="bar"  :height="height" :options="chartOptions" :series="seriesData"></apexchart>
    </div>
  </b-container>
</template>

<script>
export default {
  props: ["data","height","label","color","groups"],
  computed: {
    chartStat() {
      return this.$props.data;
    },
    chartLabels() {
      return Object.keys(this.chartStat).map((item) => {
        return this.chartStat[item].label;
      });
    },

    chartData() {
      return Object.keys(this.chartStat).map((item) => {
        return this.chartStat[item].count;

      });
    },
    seriesData(){
      let series = []
      this.chartData.forEach((item,index) => {
        series.push({name: this.$props.groups[index], data: item })
      });
      return series

    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "bar",
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        colors:[ '#0a2287', '#0275d8'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          categories: this.chartLabels,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          title:{
            text:this.$props.label,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
          },
        },
        title: {
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      };
    },
  },
  data() {
    return {};
  },
};
</script>
